import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { id: "mainApp" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_IntroductionComponent = _resolveComponent("IntroductionComponent");
    var _component_Socials = _resolveComponent("Socials");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_IntroductionComponent, { "class": "top-spacing" }),
        _createVNode(_component_Socials, { "class": "bottom-center" })
    ]));
}
