import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/Home.vue';
var routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView
    },
];
var router = createRouter({
    history: createWebHistory(),
    routes: routes
});
export default router;
