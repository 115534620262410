var ConfigStore = /** @class */ (function () {
    function ConfigStore() {
    }
    ConfigStore.socials = [
        {
            name: 'Twitter',
            iconSource: 'twitter',
            target: 'https://twitter.com/fakrl'
        },
        {
            name: 'Github',
            iconSource: 'github',
            target: 'https://github.com/anatanokami'
        },
        {
            name: 'Email',
            iconSource: 'mail',
            target: 'mailto:mail@fkrl.dev'
        },
        {
            name: 'Instagram',
            iconSource: 'instagram',
            target: 'https://www.instagram.com/fabrizio.karl'
        },
        {
            name: 'LinkedIn',
            iconSource: 'linkedin',
            target: 'https://www.linkedin.com/in/fakarl'
        },
    ];
    return ConfigStore;
}());
export { ConfigStore };
