import { defineComponent } from 'vue';
import IntroductionComponent from '../components/Introduction.vue';
import Socials from '../components/Socials.vue';
export default defineComponent({
    name: 'HeaderView',
    components: {
        IntroductionComponent: IntroductionComponent,
        Socials: Socials
    }
});
