import feather from 'feather-icons';
import { defineComponent, ref } from "vue";
import { ConfigStore } from '../configStore';
export default defineComponent({
    name: 'SocialLinks',
    setup: function () {
        var socials = ref(ConfigStore.socials);
        return {
            socials: socials
        };
    },
    mounted: function () {
        feather.replace();
    }
});
